::selection {
	background: #89cff0; /* WebKit/Blink Browsers */
	color: #fff;
}

::-moz-selection {
	background: #89cff0; /* Gecko Browsers */
	color: #fff;
}

a {
	outline: none !important;
}

#fun-facts .section-heading, #about_vila .section-heading {
    margin-bottom: 60px;
}

#about_vila .section-heading h4 {
    color: white;
}

#about_vila .line-dec {
    background-color: white;
}

#about_vila .left-text .about-vila-img {
	margin: 0 15px 15px 0;
	max-width: 200px;
	transition: transform 300ms;
}

#about_vila .left-text .about-vila-img:hover {
	transform: scale(1.05);
}

.section-heading h4 {
    font-size: 17px !important;
    margin-bottom: 10px !important;
}

.content {
    overflow: auto;
    position: relative;
    padding: 10px;
    background: #333;
    margin: 50px auto 0;
    width: 100%;
    height: auto;
}

.content li {
    margin: 0 4px;
}

.content li a {
    display: block;
    border: 7px solid rgba(255, 255, 255, .1);
}

#content-1 {
    padding: 10px 60px;
    background-color: transparent;
    width: 80%;
}

#content-1 .mTSButtonIcon {
    fill: #aaa;
}

#content-1 .mTSButton .mTSButtonIcon {
    opacity: .5;
}

#content-1 .mTSButton:hover .mTSButtonIcon {
    opacity: 1;
}

.contact-table td{
    color: white;
    font-size: 16px;
    padding-bottom: 15px;
    padding-right: 15px;
}

.contact-table td a {
    color: inherit;
}

.contact-table td a:hover {
	/* color: #d44457; */
	color: #5cbdea;
}

.social-icons a, .social-icons a:hover {
	text-decoration: none;
	color: inherit;
}

.booking-icon {
	padding-left: 4px;
}

#background-video {
	position: absolute;
	right: 0;
	bottom: 0;
	min-width: 100%; 
	min-height: 100%;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
	background-color: #000;
	opacity: 0.7;
}

#swipebox-close {
	right: calc(50% - 25px);
}

@media (max-width: 991px) {

    .contact-table {
        margin-top: 50px;
	}
	
	#about_vila .left-text .about-vila-img {
		max-width: 130px;
	}

}

@media only screen and (min-width: 768px) {
	.cd-hero-slider h2, .cd-hero-slider p {
		max-width: 580px;
	}
}